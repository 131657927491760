import React from 'react'

export const Educations = (props) => {
    var educations = props.data.sort((a,b) => a.id < b.id ? 1 : -1)
  return (
    <section className="edu about">
    <h3><span>E</span>ducation.</h3><br/>
        <div className="edu-container">
        {educations &&
					educations.map((edu) => (
            <div className="edu-box" key={edu.id}>
                <i className="fas fa-circle"></i>
                <h4>{edu.name}</h4>
                <p>{edu.institute_name}</p>
                <small>{edu.from_year} - {edu.to_year}</small>
                <p>{edu.branch_name?edu.branch_name:""}</p>
                <h5>{edu.percentage}%</h5>
            </div>
           ))}
        </div>
        </section>
  )
}
