
import React from "react";

export const Avatar = (props) => {
	return (
		<section className="container about">
			<center>
				<div className="outer-circle">
					<img
						src={"https://res.cloudinary.com/magbanum/" + props.data.avatar}
						alt="Shantanu Nighot"
					/>
					<a href={props.data.linkedin}>
						<i className="fab fa-linkedin-in"></i>
					</a>
					<a href={props.data.github}>
						<i className="fab fa-github" href=""></i>
					</a>
					<a href={props.data.instagram}>
						<i className="fab fa-instagram"></i>
					</a>
					<a href={props.data.dribble}>
						<i className="fab fa-dribbble"></i>
					</a>
					<a href={props.data.codepen}>
						<i className="fab fa-codepen"></i>
					</a>
					<a href={props.data.discord}>
						<i className="fab fa-discord"></i>
					</a>
					<a href={props.data.website}>
						<i className="fas fa-globe"></i>
					</a>
				</div>
			</center>
		</section>
	);
};
