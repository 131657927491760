import React from "react";

// CSS

import "./styles/Home.css";
import "./styles/Footer.css";
import "./styles/Navbar.css";
import "./styles/Resources.css";
import "./styles/About.css";
import "./styles/Avatar.css";
import "./styles/Loader.css";
import "./styles/Projects.css";
import "./styles/Post.css";
import "./styles/Blog.css";
import "./styles/TechStack.css";
import "./styles/Contact.css";

// Components

import Navbar from "./components/Navbar";
import { Footer } from "./components/Footer";
import { Routes, Route, Navigate } from "react-router-dom";
import Resources from "./components/Resources";
import About from "./components/About";
import Projects from "./components/Projects";
import Blog from "./components/Blog";
import Post from "./components/Post";
import Home from "./components/Home";
import TechStack from "./components/TechStack";
import Contact from "./components/Contact";
import { HelmetProvider } from "react-helmet-async";
import CommentSection from "./components/CommentSection";
import constants from "./components/Constants";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import { Component } from "react";


export default class App extends Component {
	baseUrl = constants.apiDomain + "current";
	// const [authTokens, setAuthTokens] = useState(() =>
	// 	localStorage.getItem("authTokens")
	// 		? JSON.parse(localStorage.getItem("authTokens"))
	// 		: null
	// );
	// const [currentUser, setCurrentUser] = useState("");
	constructor(props) {
		super(props);
		this.state = {
			currentUser: "",
			authTokens: localStorage.getItem("authTokens"),
		};
	}
	getData = async () => {
		var response = await fetch(this.baseUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${
					JSON.parse(this.state.authTokens).access
				}`,
			},
		});

		let temp = await response.json();
		this.setState({
			currentUser: temp.username,
		});
		
	};
	componentDidMount() {
		if(this.state.authTokens){
			this.getData();
		}
	}
	componentDidUpdate() {
		if (localStorage.getItem("authTokens") === undefined) {
			this.setState({
				authTokens: localStorage.getItem("authTokens"),
			});
			this.getData();
		}
	}
	setCurrentUser = (value) => {
		this.setState({
			currentUser: value,
		});
	};
	render() {
		var { currentUser } = this.state;
		return (
			<>
				<HelmetProvider>
					
					<Navbar currentUser={currentUser && currentUser} />
					<Routes>
						<Route
							path="/"
							element={
								<>
									<Home currentUser={currentUser} />
								</>
							}
						/>
						<Route path="/projects" element={<Projects />} />
						<Route
							path="/blog"
							element={<Blog currentUser={currentUser} />}
						/>
						<Route path="/tech-stack" element={<TechStack />} />
						<Route
							path="/blog/:slug"
							element={<Post currentUser={currentUser} />}
						/>
						<Route path="/contact" element={<Contact />} />
						<Route path="/comments" element={<CommentSection />} />
						<Route
							path="/dashboard"
							element={
								
								<Dashboard
									currentUser={currentUser}
									setCurrentUser={this.setCurrentUser}
								/>	
							}
						/>
	
						<Route
							path="/login"
							element={
								currentUser && currentUser?<Navigate to="/dashboard" />:
									<Login setCurrentUser={this.setCurrentUser} />
							}
						/>
						{/* {console.log(currentUser)} */}
						<Route
							path="/resources"
							element={<Resources data={["resources", "tags"]} />}
						/>
						<Route
							path="/about"
							element={
								<About
									data={[
										"me/6",
										"experience",
										"education",
										"certification",
									]}
									currentUser={currentUser}
								/>
							}
						/>
					</Routes>
					<Footer />
				</HelmetProvider>
			</>
		);
	}
}
