import moment from "moment";
import React from "react";

export const Intro = (props) => {
	return (
		<>
			<section className="summary about">
				<h1>About Me</h1>
				<p>
					<i className="fa fa-quote-left" href=""></i>{" "}
					{props.data.intro}{" "}
					<i className="fa fa-quote-right" href=""></i>
				</p>
			</section>
			<section className="card-1 about">
				<div className="left-block">
					<table>
						<tbody>
							<th>Birthdate:</th>
							<td>
								{moment(props.data.date_of_birth).format(
									"MMM D, YYYY"
								)}
							</td>
						</tbody>
						<tbody>
							<th>Company:</th>
							<td>Publicis Sapient</td>
						</tbody>
						<tbody>
							<th>Title:</th>
							<td>Associate Technology L1</td>
						</tbody>
						<tbody>
							<th>Email:</th>
							<td>
								{props.currentUser &&
								props.currentUser !== undefined
									? props.data.email
									: "Login to get Email Id"}
							</td>
						</tbody>
						<tbody>
							<th>Location:</th>
							<td>Nagpur, Maharashtra, India</td>
						</tbody>
					</table>

					<a href="/Shantanu_Nighot_Resume.pdf">
						<i className="fas fa-download"></i> Download Resume
					</a>
				</div>
				<div className="right-block">
					<h4>Hobbies Interest</h4>
					<br />
					<div className="hobbies-container">
						<div className="hobby-circle">
							<i className="fas fa-gamepad"></i>
							<h5>Game</h5>
						</div>
						<div className="hobby-circle">
							<i className="fas fa-music"></i>
							<h5>Music</h5>
						</div>
						<div className="hobby-circle">
							<i className="fas fa-globe-asia"></i>
							<h5>Travel</h5>
						</div>
						<div className="hobby-circle">
							<i className="fas fa-user-ninja"></i>
							<h5>Anime</h5>
						</div>
						<div className="hobby-circle">
							<i className="fas fa-microchip"></i>
							<h5>Tech</h5>
						</div>
						<div className="hobby-circle">
							<i className="fas fa-laptop-code"></i>
							<h5>Code</h5>
						</div>
						<div className="hobby-circle">
							<i className="fas fa-blog"></i>
							<h5>Blog</h5>
						</div>
						<div className="hobby-circle">
							<i className="fas fa-rupee-sign"></i>
							<h5>Money</h5>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
