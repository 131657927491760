import React, { Component } from "react";
import constants from "./Constants";
import { Loader } from "./Loader";
import { ResourceCard } from "./ResourceCard";
import AdSense from "react-adsense";

export default class Resources extends Component {
	baseUrl = constants.apiDomain;

	constructor(props) {
		super(props);
		this.state = {
			items: [],
			isLoaded: false,
			selectedResource: "all",
			filteredResource: null,
		};
	}
	getData = async () => {
		var temp = [];

		// var temp = []
		for (const element of this.props.data) {
			const response = await fetch(this.baseUrl + element);
			temp.push(await response.json());
			// console.log(temp)
		}
		// console.log(temp)
		this.setState({
			items: temp,
			filteredResource: temp[0],
			isLoaded: true,
		});

		// console.log(this.state.filteredResource);
	};

	componentDidMount() {
		this.getData();
	}

	filterResource(resourceTag) {
		let filteredResource = this.state.items[0].filter((resource) =>
			resource.tags.includes(resourceTag)
		);
		// console.log(filteredResource);

		return filteredResource;
	}
	handleResource(e) {
		let tagResource = e;
		tagResource !== "all"
			? this.setState({
					filteredResource: this.filterResource(tagResource),
			  })
			: this.setState({ filteredResource: this.state.items[0] });
		//   console.log(this.state.filteredResource)
		this.setState({
			selectedResource: tagResource,
		});
	}

	render() {
		var { isLoaded, items, filteredResource, selectedResource } =
			this.state;
		var resources = items[0];
		var tags = items[1];
		document.title = "Curated Resources by Shantanu Nighot";
		if (!isLoaded && this.props.data[0] === "resources") {
			return <>{<Loader />}</>;
		} else if (this.props.data[0] === "resources") {
			return (
				<>
					<section className="resources">
						<center>
							<div className="intro">
								<h3>Introducing</h3>
								<h1>
									<span>R</span>esources
								</h1>

								<p>
									One destination for all the resources you
									need for learning, exploring and designing.
									Here I provide the best websites, blogs,
									cheetsheets, and projects that I encountered
									and find helpful for others.
								</p>
								<p>
									Bookmark this page as new resources are
									added weekly.
								</p>
							</div>
						</center>
					</section>
					<div className="tagbox">
						<ul>
							{tags &&
								tags.map((tag, index) => (
									<li
										className={
											"btn" +
											(selectedResource === tag.slug
												? " active"
												: "")
										}
										key={index}
										value={tag.slug}
										onClick={() =>
											this.handleResource(tag.slug)
										}
									>
										{tag.name}{" "}
										<span className="badge">
											{resources &&
												resources.filter((resource) =>
													resource["tags"].includes(
														tag.slug
													)
												).length}
										</span>
									</li>
								))}
						</ul>
					</div>

					{/* Resources list start */}
					<div className="resources row" id="row">
						{filteredResource &&
							filteredResource.map((resource) => (
								<ResourceCard resource={resource} />
							))}
						{/* 
						<div className={"card "}>
							<div className="box">
							<AdSense.Google
								client="ca-pub-3936137909536285"
								slot="6675153866"
								format="fluid"
								style={{ display: "block" }}
								layoutKey='-8r+ez-1f-3v+cg'
							/>
							</div>
						</div> */}
					</div>
					{/* Resources list end */}

					{/* <section className="long-width-ad">
						<AdSense.Google
							client="ca-pub-3936137909536285"
							slot="2769295345"
							format="auto"
							responsive="true"
							style={{ display: "block" }}
						/>
					</section> */}
				</>
			);
		}
	}
}
