import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
export default class Home extends Component {
	render() {
		document.title = "Shantanu Nighot | Self-taught python developer from India"
		return (
			<><Helmet prioritizeSeoTags>
			<title>
				Shantanu Nighot | Self-taught developer from Nagpur
			</title>
			<meta name="author" content="Shantanu Nighot" />
			<meta
				property="og:title"
				content="Shantanu Nighot | Self-taught developer from Nagpur"
			/>
			<meta property="og:site_name" content="magbanum.com" />
			<meta property="og:type" content="website" />
			<meta
				property="og:url"
				content="https://magbanum.com"
			/>
			<meta
				name="image"
				property="og:image"
				content="https://res.cloudinary.com/magbanum/image/upload/v1627746320/Personal%20website/og_image_portfolio_tdidww.png"
				itemprop="image"
			/>
			<meta
				name="description"
				content="A self-taught developer who enjoys creating things that live on the internet. He is familiar with the developement and deployment process of many web technologies. He knows about Python, Django, Heroku, MongoDB, Git and many other web technologies. Shantanu is available for any web developement and graphic design freelance projects."
				itemprop="description"
			/>
			<meta
				property="og:description"
				content="A self-taught developer who enjoys creating things that live on the internet. He is familiar with the developement and deployment process of many web technologies. He knows Python, Django, Heroku, MongoDB, Git and many other web technologies. Shantanu is available for any web developement and graphic design freelance projects."
			/>
		</Helmet>
			<section className="home" id="home">
				<div className="max-width">
					<div className="home-content">
						<div className="column left">
							<div className="text-1">Hi, my name is</div>
							<div className="text-2">Shantanu Nighot.</div>
							<div className="text-2">
								I create things on the web.
							</div>
							<div className="text-3">
								I am college-taught Electrical engineer and
								self-taught developer. I enjoy learning new
								technologies and build things. My primary
								programming language is Python. I am currently
								learning Javascript and React.
							</div>
							<a href="/Shantanu_Nighot_Resume.pdf">Resume.</a>
						</div>
						{/* <div className="column right">
						<img
							src="https://res.cloudinary.com/magbanum/image/upload/v1615049313/Profile/Profile-2_msqbb4.jpg"
							alt="Shantanu Nighot"
						/>
					</div> */}
					</div>
				</div>
			</section></>
		);
	}
}
