import "./Styles/Comment.scss";

import AddComment from "./AddComment";
import DeleteModal from "./DeleteModal";
import CommentVotes from "./CommentVotes";
import CommentHeader from "./CommentHeader";
import CommentFooter from "./CommentFooter";
import constants from "../Constants";
import { Component } from "react";

export default class Reply extends Component {
	baseUrl = constants.apiDomain + "comments/";
	constructor(props) {
		super(props);
		this.state = {
			replying: false,
			time: this.props.commentPostedTime(this.differenceInTime),
			vote: this.props.commentData.liked,
			score: this.props.commentData.score,
			editing: false,
			content: this.props.commentData.body,
			deleting: false,
			commentData: this.props.commentData,
			currentUser: this.props.currentUser,
			authTokens: localStorage.getItem("authTokens"),
		};
	}

	// get time from comment posted
	createdAt = new Date(this.props.commentData.createdAt);

	today = new Date();
	differenceInTime = this.today.getTime() - this.createdAt.getTime();

	// adding reply
	addReply = (newReply) => {
		this.props.addNewReply(newReply);
		this.setState({
			replying: false,
		});
	};

	commentContent = () => {
		const text = this.state.commentData.body.trim().split(" ");
		const firstWord = text.shift().split(",");

		return !this.state.editing ? (
			<div className="comment-content">
				<span className="replyingTo">{firstWord}</span>
				{text.join(" ")}
			</div>
		) : (
			<textarea
				className="content-edit-box"
				value={this.state.content}
				onChange={(e) => {
					this.setState({
						content: e.target.value,
					});
				}}
			/>
		);
	};
	componentDidUpdate(prevProps) {
		if (this.props.commentData !== prevProps.commentData) {
			this.setState({
				commentData: this.props.commentData,
			});
		}
	}
	updateComment = () => {
		if (this.state.content === "" || this.state.content === " ") {
			alert("Comment can not be empty");
		} else {
			const updatedComment = {
				body: this.state.content,
				active: true,
				post: this.state.commentData.post,
				parent: this.state.commentData.parent,
			};

			fetch(this.baseUrl + this.state.commentData.id, {
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${
						JSON.parse(this.state.authTokens).access
					}`,
				},
				body: JSON.stringify(updatedComment),
			}).then((response) => {
				if (response.status === 200) {
					this.props.editComment(
						this.state.content,
						this.props.commentData.id,
						"reply"
					);
					this.setState({
						editing: false,
					});
				} else {
					alert("Something went wrong");
				}
			});
		}
	};

	// delete comment
	deleteReply = () => {
		console.log(this.state.commentData.id);
		this.props.deleteComment(this.state.commentData.id, "reply");

		this.setState({
			deleting: false,
		});
	};

	setReplying = (value) => {
		this.setState({
			replying: value,
		});
	};

	setVoted = (value) => {
		this.setState({
			vote: value,
		});
	};

	setScore = (value) => {
		this.setState({
			score: value,
		});
	};

	setDeleting = (value) => {
		this.setState({
			deleting: value,
		});
	};

	setEditing = (value) => {
		this.setState({
			editing: value,
		});
	};

	setContent = (value) => {
		this.setState({
			content: value,
		});
	};
	render() {
		var {
			commentData,
			vote,
			score,
			time,
			editing,
			replying,
			deleting,
			currentUser,
		} = this.state;
		return (
			<div
				className={`comment-container ${
					commentData?.replies[0] !== undefined ? "gap" : ""
				}`}
			>
				<div className="comment">
	
					<CommentVotes
						vote={vote}
						setVoted={this.setVoted}
						score={score}
						setScore={this.setScore}
						commentData={commentData}
						currentUser={currentUser}
					/>
					<div className="comment--body">
						<CommentHeader
							commentData={commentData}
							setReplying={this.setReplying}
							setDeleting={this.setDeleting}
							setDeleteModalState={this.props.setDeleteModalState}
							setEditing={this.setEditing}
							time={time && time}
							currentUser={currentUser}
						/>

						{this.commentContent()}
						{editing && (
							<button
								className="update-btn"
								onClick={this.updateComment}
							>
								update
							</button>
						)}
					</div>
					<CommentFooter
						vote={vote}
						setVoted={this.setVoted}
						score={score}
						setScore={this.setScore}
						commentData={commentData}
						setReplying={this.setReplying}
						setDeleting={this.setDeleting}
						setDeleteModalState={this.props.setDeleteModalState}
						setEditing={this.setEditing}
					/>
				</div>

				{replying && (
					<AddComment
						buttonValue={"reply"}
						addComments={this.addReply}
						replyingTo={commentData.user}
						post={commentData.post}
						parent={commentData.id}
					/>
				)}
				{commentData.replies.map((reply) => (
					<Reply
						key={reply.id}
						commentData={reply}
						commentPostedTime={this.props.commentPostedTime}
						addNewReply={this.addReply}
						currentUser={currentUser}
						editComment={this.props.editComment}
						deleteComment={this.props.deleteComment}
						setDeleteModalState={this.props.setDeleteModalState}
						time={this.props.time}
					/>
				))}

				{deleting && (
					<DeleteModal
						setDeleting={this.setDeleting}
						deleteComment={this.deleteReply}
						setDeleteModalState={this.props.setDeleteModalState}
					/>
				)}
			</div>
		);
	}
}
