import React from "react";

export const Tech = ({tech}) => {
	return (
		<li className="tech-cell">
			<img
				src={"https://res.cloudinary.com/magbanum/" + tech.cover}
				alt={tech.alt}
			/>
		</li>
	);
};
