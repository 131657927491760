import { ReactComponent as IconPlus } from "./Assets/images/icon-plus.svg";
import { ReactComponent as IconMinus } from "./Assets/images/icon-minus.svg";
import { Component } from "react";
import constants from "../Constants";

export default class CommentVotes extends Component {
	baseUrl =
		constants.apiDomain +
		"post/" +
		this.props.commentData.post +
		"/comments/" +
		this.props.commentData.id +
		"/likes";

	constructor(props) {
		super(props);
		this.state = {
			commentData: this.props.commentData,
			authTokens: localStorage.getItem("authTokens"),
			currentUser: this.props.currentUser,
		};
	}
	// up vote and down vote
	upVote = () => {
    
		if (this.props.vote === false) {
			fetch(this.baseUrl, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${
						JSON.parse(this.state.authTokens).access
					}`,
				},
			});
			let n = this.props.score + 1;
			this.props.setScore(n);
			this.props.setVoted(true);
		}
	};

	downVote = () => {
		if (this.props.vote === true) {
			fetch(this.baseUrl, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${
						JSON.parse(this.state.authTokens).access
					}`,
				},
			});
			let n = this.props.score - 1;
			this.props.setScore(n);
			this.props.setVoted(false);
		}
	};

	render() {
		var { currentUser } = this.state;
		return (
			<div className="comment--votes">
				<button
					className="plus-btn"
					onClick={currentUser && currentUser !== undefined ? this.upVote : null}
					aria-label="plus-btn"
				>
					<IconPlus />
				</button>
				<div className="votes-counter">{this.props.score}</div>
        {/* {console.log(this.props.vote, this.props.commentData.body)} */}
				<button
					className="minus-btn"
					onClick={currentUser && currentUser !== undefined ? this.downVote : null}
					aria-label="minus-btn"
				>
					<IconMinus />
				</button>
			</div>
		);
	}
}
