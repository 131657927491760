import "./Styles/Comment.scss";

import AddComment from "./AddComment";
import ReplyContainer from "./ReplyContainer";
import DeleteModal from "./DeleteModal";
import CommentVotes from "./CommentVotes";
import CommentHeader from "./CommentHeader";
import CommentFooter from "./CommentFooter";
import constants from "../Constants";
import { commentPostedTime } from "./utils";
import { Component } from "react";

class Comment extends Component {
	baseUrl =
		constants.apiDomain +
		"post/" +
		this.props.commentData.post +
		"/comments/";
	constructor(props) {
		super(props);
		this.state = {
			replying: false,
			time: "",
			vote: this.props.commentData.liked,
			score: this.props.commentData.score,
			editing: false,
			content: this.props.commentData.body,
			deleting: false,
			currentUser: this.props.currentUser,
			commentData: this.props.commentData,
			authTokens: localStorage.getItem("authTokens"),
		};
	}

	// get time from comment posted
	createdAt = new Date(this.props.commentData.createdAt);
	today = new Date();
	differenceInTime = this.today.getTime() - this.createdAt.getTime();

	componentDidMount() {
		this.setState({
			time: commentPostedTime(this.differenceInTime),
		});
	}

	addReply = (newReply) => {
		// const replies = [...this.props.commentData.replies, newReply];
		this.props.updateReplies(newReply);
		this.setState({
			replying: false,
		});
	};

	updateComment = () => {
		if (this.state.content === "" || this.state.content === " ") {
			alert("Comment can not be empty");
		} else {
			const updatedComment = {
				body: this.state.content,
				active: true,
				post: this.state.commentData.post,
				parent: this.state.commentData.parent,
			};

			fetch(this.baseUrl + this.state.commentData.id, {
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${
						JSON.parse(this.state.authTokens).access
					}`,
				},
				body: JSON.stringify(updatedComment),
			}).then((response) => {
				if (response.status === 200) {
					this.props.editComment(
						this.state.content,
						this.props.commentData.id,
						"comment"
					);
					this.setState({
						editing: false,
					});
				} else {
					alert("Something went wrong");
				}
			});
		}
	};

	deleteComment = (id, type) => {
		try {
			fetch(this.baseUrl + id, {
				method: "DELETE",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${
						JSON.parse(this.state.authTokens).access
					}`,
				},
			}).then((response) => {
				if (response.status === 204) {
					this.props.commentDelete();
				} else {
					alert("Something went wrong");
				}
			});
		} catch (error) {
			alert("Something went wrong", error);
		}

		this.setState({
			deleting: false,
		});
	};
	setReplying = (value) => {
		this.setState({
			replying: value,
		});
	};

	setVoted = (value) => {
		this.setState({
			vote: value,
		});
	};

	setScore = (value) => {
		this.setState({
			score: value,
		});
	};

	setDeleting = (value) => {
		this.setState({
			deleting: value,
		});
	};

	setEditing = (value) => {
		this.setState({
			editing: value,
		});
	};

	setContent = (value) => {
		this.setState({
			content: value,
		});
	};
	render() {
		var { vote, score, time, content, editing, currentUser } = this.state;
		// console.log(this.state.deleting)
		if (this.props.commentData.parent === null) {
			return (
				<div
					className={`comment-container ${
						this.props.commentData.replies !== []
							? "reply-container-gap"
							: ""
					}`}
					key={this.props.commentData.id}
				>
					<div className="comment">
						<CommentVotes
							vote={vote}
							setVoted={this.setVoted}
							score={score}
							setScore={this.setScore}
							commentData={this.props.commentData}
							currentUser={currentUser}
						/>
						<div className="comment--body">
							{time && (
								<CommentHeader
									commentData={this.props.commentData}
									setReplying={this.setReplying}
									setDeleting={this.setDeleting}
									setDeleteModalState={
										this.props.setDeleteModalState
									}
									setEditing={this.setEditing}
									time={time}
									currentUser={currentUser}
								/>
							)}

							{!editing ? (
								<div className="comment-content">
									{this.props.commentData.body}
								</div>
							) : (
								<textarea
									className="content-edit-box"
									value={content}
									onChange={(e) => {
										this.setContent(e.target.value);
									}}
								/>
							)}
							{editing && (
								<button
									className="update-btn"
									onClick={this.updateComment}
								>
									update
								</button>
							)}
						</div>
						<CommentFooter
							vote={vote}
							setVoted={this.setVoted}
							score={score}
							setScore={this.setScore}
							commentData={this.props.commentData}
							setReplying={this.setReplying}
							setDeleting={this.setDeleting}
							setDeleteModalState={this.props.setDeleteModalState}
							setEditing={this.setEditing}
							currentUser={currentUser}
						/>{" "}
					</div>

					{this.state.replying && currentUser && (
						<AddComment
							buttonValue={"reply"}
							addComments={this.addReply}
							replyingTo={this.props.commentData.user}
							post={this.props.commentData.post}
							parent={this.props.commentData.id}
						/>
					)}

					{this.props.commentData.replies?.length !== undefined ? (
						<ReplyContainer
							key={
								this.props.commentData.replies?.id !== undefined
									? this.props.commentData.replies.id
									: null
							}
							commentData={this.props.commentData.replies}
							commentPostedTime={commentPostedTime}
							addReply={this.addReply}
							editComment={this.props.editComment}
							deleteComment={this.deleteComment}
							setDeleteModalState={this.props.setDeleteModalState}
							time={time}
							currentUser={currentUser}
						/>
					) : (
						" "
					)}

					{this.state.deleting && (
						<DeleteModal
							setDeleting={this.setDeleting}
							deleteComment={this.deleteComment}
							setDeleteModalState={this.props.setDeleteModalState}
							commentData={this.props.commentData}
						/>
					)}
				</div>
			);
		}
	}
}

export default Comment;
