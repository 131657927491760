import React from "react";

export const ResourceCard = (props) => {
	return (
		<div className={"card " + props.resource.tags.join(" ")}>
			<div className="box">
				{props.resource.new ? <div className="new">NEW</div> : ""}
				<img
					src={
						"https://res.cloudinary.com/magbanum/" +
						props.resource.cover
					}
					alt={props.resource.name}
				/>
				<p>{props.resource.description}</p>
				<a href={props.resource.url}>
					<h4>{props.resource.name}</h4>
				</a>
			</div>
		</div>
	);
};
