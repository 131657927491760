import React, { Component } from "react";
import { Avatar } from "./Avatar";

import { Intro } from "./Intro";
import { Experiences } from "./Experiences";
import { Educations } from "./Educations";
import { Certifications } from "./Certifications";
import { Loader } from "./Loader";
import constants from "./Constants";

export default class About extends Component {

	baseUrl = constants.apiDomain;
	
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			isLoaded: false,
			currentUser: "",
			authTokens: localStorage.getItem("authTokens"),
		};
	}

	getData = async () => {
		var temp = []

		// var temp = []
		for (const element of this.props.data) {
			const response = await fetch(this.baseUrl + element)
			temp.push(await response.json())
			// console.log(temp)
		}
		// console.log(temp)
		this.setState({
			isLoaded: true,
			items: temp,
		});
	}
	getCurrentUser = async () => {
		var response = await fetch(this.baseUrl + "current", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${
					JSON.parse(this.state.authTokens).access
				}`,
			},
		});

		let temp = await response.json();
		this.setState({
			currentUser: temp.username,
		});
	};
	componentDidMount() {
		this.getData();
		if (this.state.authTokens) {
			this.getCurrentUser();
		}
	}

	render(){
		var { isLoaded, items, currentUser } = this.state;
		document.title = "About Shantanu Nighot"
		if (!isLoaded) {
			return (<Loader/>);
		}else{
			return <>{items !== [] &&
				<>
					<Avatar data={items[0]}/>
					<Intro data={items[0]} currentUser={currentUser}/>
		
					<Experiences data={items[1]} />
					<Educations data={items[2]} />
					<Certifications data={items[3]} />
				</>
			}</>;
		}
		
	}
	
};
