import Reply from "./Reply";
import React, { Component } from "react";

export default class ReplyContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			currentUser: this.props.currentUser,
			authTokens: localStorage.getItem("authTokens"),
			commentData: this.props.commentData,
			time: this.props.time,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.commentData !== prevProps.commentData) {
			this.setState({
				commentData: this.props.commentData,
			});
		}
	}

	render() {
		var { commentData, time, currentUser } = this.state;
		return (
			<div className="reply-container">
				{commentData?.length !== undefined
					? commentData.map((data) => (
							<Reply
              
								key={data.id}
								commentData={data}
								commentPostedTime={this.props.commentPostedTime}
								addNewReply={this.props.addReply}
								editComment={this.props.editComment}
								deleteComment={this.props.deleteComment}
								setDeleteModalState={
									this.props.setDeleteModalState
								}
								time={time}
								currentUser={currentUser}
							/>
					  ))
					: " "}
			</div>
		);
	}
}
