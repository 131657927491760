import React, { Component } from "react";
import { Loader } from "./Loader";
import { Link } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import constants from "./Constants";
import AdSense from "react-adsense";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default class Blog extends Component {
	baseUrl = constants.apiDomain + "posts";
	allPosts = [];
	index = 2;

	constructor(props) {
		super(props);
		this.state = {
			items: [],
			isLoaded: false,
			featured: {},
			hasMore: true,
		};
	}

	getData = async () => {
		// Get list of posts

		var response = await fetch(this.baseUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
		this.allPosts = await response.json();

		this.setState({
			items: this.allPosts.slice(0, 2),
			featured: this.allPosts[0],
			isLoaded: true,
		});
	};

	fetchMoreData = () => {
		if (this.state.items.length >= this.allPosts.length) {
			this.setState({ hasMore: false });
			return;
		}
		// a fake async api call like which sends
		// 20 more records in .5 secs
		// console.log(this.state.items)
		// console.log(this.index)
		setTimeout(() => {
			this.setState({
				items: this.state.items.concat(
					this.allPosts.slice(this.index, (this.index += 2))
				),
			});
		}, this.allPosts.length);
	};

	componentDidMount() {
		this.getData();
	}

	// componentDidUpdate() {
	// 	this.getData();
	// }

	render() {
		var { isLoaded, items, featured, hasMore } = this.state;
		document.title = "Technical blog by Shantanu Nighot";
		if (!isLoaded) {
			return <>{<Loader />}</>;
		} else {
			return (
				<>
					<HelmetProvider>
						<Helmet prioritizeSeoTags>
							<title>Technical blog by Shantanu Nighot</title>
							<meta name="author" content="Shantanu Nighot" />
							<meta
								property="og:title"
								content="Technical blog by Shantanu Nighot"
							/>
							<meta
								property="og:site_name"
								content="magbanum.com/blog"
							/>
							<meta property="og:type" content="blog" />
							<meta
								property="og:url"
								content="https://magbanum.com/blog"
							/>
							<meta
								name="image"
								property="og:image"
								content="https://res.cloudinary.com/magbanum/image/upload/v1675600273/Personal%20website/blog-og_cs9ccp.png"
								itemprop="image"
							/>
							<meta
								name="description"
								content="This is the Technical blog created by Shantanu Nighot. On this blog, he post usefull articles related to programming languages, web development and different tech stacks."
								itemprop="description"
							/>
							<meta
								property="og:description"
								content="This is the Technical blog created by Shantanu Nighot. On this blog, he post usefull articles related to programming languages, web development and different tech stacks."
							/>
						</Helmet>
						<Link
							className="featured-article"
							to={"/blog/" + featured.slug}
							item={featured.id}
						>
							<div className="featured-left">
								<img
									src={
										featured.hashnode_synced
											? featured.cover_image.slice(13)
											: "https://res.cloudinary.com/magbanum/" +
											  featured.cover_image
									}
									alt="Featured article cover"
								/>
							</div>

							<div className="featured-right">
								<div className="featured-tag">
									Featured Article ⭐
								</div>
								<h1>{featured.title}</h1>
								<div className="description">
									{featured.brief}
								</div>
								<div className="tags">
									{featured.tags.map((tag) => (
										<small>{tag.name}</small>
									))}
								</div>
							</div>
						</Link>

						<section className="article-list">
							<InfiniteScroll
								dataLength={items.length} //This is important field to render the next data
								next={this.fetchMoreData}
								hasMore={hasMore}
								loader={<h4>Loading...</h4>}
								endMessage={
									<p style={{ textAlign: "center" }}>
										<b>Yay! That's all for now.</b>
									</p>
								}
								// below props only if you need pull down functionality
								// refreshFunction={this.render}
								// pullDownToRefresh
								// pullDownToRefreshThreshold={50}
								// pullDownToRefreshContent={
								// 	<h3 style={{ textAlign: "center" }}>
								// 		&#8595; Pull down to refresh
								// 	</h3>
								// }
								// releaseToRefreshContent={
								// 	<h3 style={{ textAlign: "center" }}>
								// 		&#8593; Release to refresh
								// 	</h3>
								// }
							>
								{items.map((post) => (
									<div className="article-card">
										<Link to={"/blog/" + post.slug}>
											<div className="article">
												<img
													src={
														post.hashnode_synced
															? post.cover_image.slice(
																	13
															  )
															: "https://res.cloudinary.com/magbanum/" +
															  post.cover_image
													}
													alt={post.title}
												/>
												<h1>{post.title}</h1>
												<p>{post.brief}</p>
												<small>
													Date:{" "}
													{moment(
														post.publish_date
													).format(
														"MMM D, YYYY"
													)}{" "}
												</small>
											</div>
										</Link>
									</div>
								))}
							</InfiniteScroll>
						</section>

						<section className="long-width-ad">
							<AdSense.Google
								client="ca-pub-3936137909536285"
								slot="2766594791"
								format="auto"
								responsive="true"
								style={{ display: "block" }}
							/>
						</section>
					</HelmetProvider>
				</>
			);
		}
	}
}
