import React, { Component } from "react";
import constants from "./Constants";
import { Loader } from "./Loader";
import { Tech } from "./Tech";

export default class TechStack extends Component {
	baseUrl = constants.apiDomain + "techstack";

	constructor(props) {
		super(props);
		this.state = {
			items: [],
			isLoaded: false,
		};
	}

	getData = async () => {
		var response = await fetch(this.baseUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
		this.setState({
			isLoaded: true,
			items: await response.json(),
		});
	};
	componentDidMount() {
		this.getData();
	}

	render() {
		var { isLoaded, items } = this.state;
		if (!isLoaded) {
			return <>{<Loader />}</>;
		} else {
			return (
				<section className="tech-stack" id="tech-stack">
					<div className="max-width">
						<h2 className="title">tech-stack</h2>
						<div className="wrapper">
							<ul className="tech-container">
								{items.map((tech) => (
									<Tech tech={tech} />
								))}
							</ul>
						</div>
					</div>
				</section>
			);
		}
	}
}
