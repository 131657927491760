import React, { Component } from "react";
import { ModeButton } from "./ModeButton";
import { Link } from "react-router-dom";
import constants from "./Constants";

export default class Navbar extends Component {
	baseUrl = constants.apiDomain + "current";
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			currentUser: "",
			authTokens: localStorage.getItem("authTokens"),
		};
	}
	getData = async () => {
		var response = await fetch(this.baseUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${
					JSON.parse(this.state.authTokens).access
				}`,
			},
		});
		let temp = await response.json();
		this.setState({
			currentUser: temp.username,
		});
	};

	componentDidMount() {
		this.setState({
			authTokens: localStorage.getItem("authTokens"),
		});
		if (this.state.authTokens) {
			this.getData();
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				currentUser: this.props.currentUser,
			});
		}
	}

	toggleClass = () => {
		this.setState({
			isActive: !this.state.isActive,
		});
	};

	render() {
		var { isActive, currentUser } = this.state;
		return (
			<nav className="navbar">
				<div className="max-width">
					<div className="logo">
						<Link to={"/"}>
							<span>#</span>magbanum
						</Link>
					</div>
					<ul className={isActive ? "menu active" : "menu "}>
						<li>
							<Link
								className="menu-btn"
								to={"blog"}
								onClick={this.toggleClass}
							>
								Blog
							</Link>
						</li>
						{/* <li>
							<Link
								className="menu-btn"
								to={"tech-stack"}
								onClick={this.toggleClass}
							>
								Tech-stack
							</Link>
						</li> */}
						<li>
							<Link
								className="menu-btn"
								to={"projects"}
								onClick={this.toggleClass}
							>
								Projects
							</Link>
						</li>
						<li>
							<Link
								className="menu-btn"
								to={"resources"}
								onClick={this.toggleClass}
							>
								Resources
							</Link>
						</li>
						<li>
							<Link
								className="menu-btn"
								to={"about"}
								onClick={this.toggleClass}
							>
								About
							</Link>
						</li>
						<li>
							<Link
								className="menu-btn"
								to={"contact"}
								onClick={this.toggleClass}
							>
								Contact
							</Link>
						</li>

						<li>
							{currentUser ? (
								<Link
									className="menu-btn"
									to={"dashboard"}
									onClick={this.toggleClass}
								>
									Dashboard
								</Link>
							) : (
								<Link
									className="menu-btn"
									to={"dashboard"}
									onClick={this.toggleClass}
								>
									Login/Signup
								</Link>
							)}

							{/* {currentUser ? (
								<Link to={"/"} onClick={this.handleLogout}>
									{currentUser}
								</Link>
							) : (
								<Login getData={this.getData}/>
							)} */}
						</li>

						<li>
							<ModeButton />
						</li>
					</ul>
					<div className="menu-btn" onClick={this.toggleClass}>
						<i
							className={
								isActive ? "fas fa-bars active" : "fas fa-bars "
							}
						></i>
					</div>
				</div>
			</nav>
		);
	}
}
