import axios from "axios";
import React, { Component } from "react";
import constants from "./Constants";

export default class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			subject: "",
			message: "",
			submitted: false,
		};
	}

	sendMessage = async () => {
		let formField = new FormData();

		formField.append("name", this.state.name);
		formField.append("email", this.state.email);
		formField.append("subject", this.state.subject);
		formField.append("message", this.state.message);

		await axios({
			method: "post",
			url: constants.apiDomain + "messages",
			data: formField,
		}).then((response) => {
			if (response.status === 201) {
				this.setState({
					submitted: true,
				});
			}
		});
	};

	render() {
		var { name, email, subject, message, submitted } = this.state;
		return (
			<section className="contact">
				<div className="max-width">
					<h2 className="title">contact</h2>
					<div className="contact-content">
						<div className="column left">
							<div className="text">Get in Touch</div>
							<p>I am open for collaboration.</p>
							<p>
								I am looking for job opportunities related to my
								skills and for career development within
								organization.
							</p>

							<p>Any freelance projects are always welcomed.</p>
							<p>
								Send direct mail or message me using the form
								given.
							</p>

							<div className="signature">Shantanu Nighot</div>
						</div>

						<div className="column right">
							<div id="container">
								<form id="contact" >
									
									<input
										name="name"
										placeholder="Your Name"
										type="text"
										id="name"
										value={name}
										onChange={(e) =>
											this.setState({
												name: e.target.value,
											})
										}
										required
									/>

									
									<input
										name="email"
										placeholder="Your email"
										type="email"
										id="email"
										value={email}
										onChange={(e) =>
											this.setState({
												email: e.target.value,
											})
										}
										required
									/>

									
									<input
										name="subject"
										placeholder="Subject here"
										type="text"
										id="subject"
										value={subject}
										onChange={(e) =>
											this.setState({
												subject: e.target.value,
											})
										}
										required
									></input>

									
									<textarea
										name="message"
										placeholder="What's in your mind?"
										type="textarea"
										id="messege"
										value={message}
										onChange={(e) =>
											this.setState({
												message: e.target.value,
											})
										}
										required
									></textarea>
									<br />
									<br />

									<input
										type="submit"
										onClick={this.sendMessage}
									/>
								</form>
								{submitted ? window.alert("Hi, " + name + "! Message sent successfully")
									: "" }
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
