import React from "react";
import "../styles/ModeButton.css";

export const ModeButton = () => {
	const handleClick = () => {
		// 👇️ toggle class on click
		document.body.classList.toggle("dark-theme");
	};
	return (
	
			<div className="switch">
				<input
					type="checkbox"
					className="switch__input"
					id="Switch"
					onChange={handleClick}
                    defaultChecked
				/>
				<label className="switch__label" htmlFor="Switch">
					<span className="switch__indicator"></span>
					<span className="switch__decoration"></span>
				</label>
			</div>

	);
};
