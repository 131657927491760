import React, { Component } from "react";
import Modal from "react-modal";
import "../styles/Login.css";
import axios from "axios";
import constants from "./Constants";
import jwt_decode from "jwt-decode";
import { Navigate } from "react-router-dom";

Modal.setAppElement("#root");

export default class Login extends Component {
	subtitle = "";
	loginUrl = constants.apiDomain + "login";
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: true,
			currentView: "logIn",
			username: "",
			email: "",
			password: "",
			password2: "",
			authTokens: localStorage.getItem("authTokens")
				? JSON.parse(localStorage.getItem("authTokens"))
				: null,
			user: localStorage.getItem("authTokens")
				? jwt_decode(localStorage.getItem("authTokens"))
				: null,
			loading: true,
			currentUser: "",
		};
	}

	openModal = () => {
		this.setState({
			modalIsOpen: true,
		});
	};

	afterOpenModal = () => {
		// references are now sync'd and can be accessed.
		this.subtitle.style.color = "#f00";
	};

	closeModal = () => {
		this.setState({
			modalIsOpen: false,
		});
	};

	changeView = (view) => {
		this.setState({
			currentView: view,
		});
	};

	handleSignup = async (event) => {
		event.preventDefault();
		let formField = new FormData();

		formField.append("username", this.state.username);
		formField.append("email", this.state.email);
		formField.append("password", this.state.password);
		formField.append("password2", this.state.password2);
		await axios({
			method: "post",
			url: constants.apiDomain + "register",
			data: formField,
		}).then((response) => {
			if (response.status === 201) {
				localStorage.setItem("authTokens", JSON.stringify(response.data));
				this.closeModal();
				this.setState({
					currentUser: response.data['user']
				})
				this.props.setCurrentUser(response.data['user']);
			} else {
				alert("Something went wrong! " + response.data)
			}
		});
	};

	handleLogin = async (event) => {
		event.preventDefault();
		const username = this.state.username;
		const password = this.state.password;
		const response = await fetch(this.loginUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username,
				password,
			}),
		});

		const data = await response.json();

		if (response.status === 200) {
			this.setState({
				authTokens: data,
				user: jwt_decode(data.access),
			});
			localStorage.setItem("authTokens", JSON.stringify(data));
			this.closeModal();
			this.setState({
				currentUser: username,
			});
			this.props.setCurrentUser(username);
		} else {
			alert("Something went wrong!");
		}
	};

	componentDidMount() {
		if (this.state.authTokens) {
			this.setState({
				user: jwt_decode(this.state.authTokens.access),
				loading: false,
			});
		}
	}

	currentView = () => {
		switch (this.state.currentView) {
			case "signUp":
				return (
					<form>
						<h2>Sign Up!</h2>
						<fieldset>
							<legend>Create Account</legend>
							<ul>
								<li key={"username"}>
									<input
										name="username"
										placeholder="Your Name"
										type="text"
										id="name"
										onChange={(e) =>
											this.setState({
												username: e.target.value,
											})
										}
										required
									/>
								</li>
								<li key={"email"}>
									<input
										name="email"
										placeholder="Your email"
										type="email"
										id="email"
										onChange={(e) =>
											this.setState({
												email: e.target.value,
											})
										}
										required
									/>
								</li>
								<li key={"password"}>
									<input
										name="password"
										placeholder="Password"
										type="password"
										id="password"
										onChange={(e) =>
											this.setState({
												password: e.target.value,
											})
										}
										required
									/>
								</li>
								<li key={"password2"}>
									<input
										name="password2"
										placeholder="Confirm Password"
										type="password"
										id="password2"
										onChange={(e) =>
											this.setState({
												password2: e.target.value,
											})
										}
										required
									/>
								</li>
							</ul>
						</fieldset>
						<button type="submit" onClick={this.handleSignup}>
							Submit
						</button>
						<button
							type="button"
							onClick={() => this.changeView("logIn")}
						>
							Have an Account?
						</button>
					</form>
				);
				break;
			case "logIn":
				return (
					<form>
						<h2>Welcome Back!</h2>
						<fieldset>
							<legend>Log In</legend>
							<ul>
								<li key={"username"}>
									<input
										name="username"
										placeholder="Your Name"
										type="text"
										id="name"
										onChange={(e) =>
											this.setState({
												username: e.target.value,
											})
										}
										required
									/>
								</li>
								<li key={"password"}>
									<input
										name="password"
										placeholder="Password"
										type="password"
										id="password"
										onChange={(e) =>
											this.setState({
												password: e.target.value,
											})
										}
										required
									/>
								</li>
								{/* <li key={"link"}>
									<i />
									<a
										onClick={() =>
											this.changeView("PWReset")
										}
										href="#"
									>
										Forgot Password?
									</a>
								</li> */}
							</ul>
						</fieldset>
						<button onClick={this.handleLogin}>Login</button>
						<button
							type="button"
							onClick={() => this.changeView("signUp")}
						>
							Create an Account
						</button>
					</form>
				);
				break;
			case "PWReset":
				return (
					<form>
						<h2>Reset Password</h2>
						<fieldset>
							<legend>Password Reset</legend>
							<ul>
								<li key={"description"}>
									<em>
										A reset link will be sent to your inbox!
									</em>
								</li>
								<li>
									<label for="email">Email:</label>
									<input type="email" id="email" required />
								</li>
							</ul>
						</fieldset>
						<button>Send Reset Link</button>
						<button
							type="button"
							onClick={() => this.changeView("logIn")}
						>
							Go Back
						</button>
					</form>
				);
			default:
				break;
		}
	};

	render() {
		var { currentUser } = this.state;
		
		if (!currentUser) {
			return (
				<div className="login-page">
					<a href="/login" type="button" className="login-button" onClick={this.openModal}>Login/Signup</a>

					<Modal
						isOpen={this.state.modalIsOpen}
						onRequestClose={this.closeModal}
						className="Modal"
						overlayClassName="Overlay"
						contentLabel="Example Modal"
					>
						<section id="entry-page">{this.currentView()}</section>
					</Modal>
				</div>
			);
		} else {
			return <Navigate to="/dashboard" />;
		}
	}
}
