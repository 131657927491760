import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import constants from "./Constants";
import "../styles/Dashboard.css";

export default class Dashboard extends Component {
	baseUrl = constants.apiDomain + "logout";
	constructor(props) {
		super(props);
		this.state = {
			isActive: true,
			currentUser: this.props.currentUser,
			authTokens: localStorage.getItem("authTokens"),
		};
	}
	handleLogout = async () => {
		await fetch(this.baseUrl, {
			method: "POST",
		});
		localStorage.removeItem("authTokens");
		this.setState({
			currentUser: "",
		});
		this.props.setCurrentUser("");
	};
	setCurrentUser = (value) => {
		this.setState({
			currentUser: value,
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				currentUser: this.props.currentUser,
			});
		}
	}

	render() {
		var { currentUser } = this.state;

		return currentUser ? (
			<div className="dashboard-page">
				<p>Hi {currentUser}</p>
				<Link to={""} onClick={this.handleLogout} className="logout">
					Logout
				</Link>
				<div className="maintainance">
					This space is still under progress. I am bringing some
					useful stuff here so please stay tuned. Also, if you have
					any suggestions on this space then do let me know using
					contact form. Thanks.😊
				</div>
			</div>
		) : (
			<Navigate to="/login" />
		);
	}
}
