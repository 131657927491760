import moment from "moment";
import React from "react";

export const Experiences = (props) => {
	var experiences = props.data.sort((a, b) => (a.id < b.id ? 1 : -1));
	return (
		<section className="edu about">
			<h3>
				<span>E</span>xperience.
			</h3>
			<br />
			<div className="edu-container">
				{experiences &&
					experiences.map((exp) => (
						<div className="edu-box" key={exp.id}>
							<i className="fas fa-circle"></i>
							<h4>{exp.job_role}</h4>
							<p>
								{exp.company} - {exp.location}
							</p>
							<small>
								{moment(exp.date_joined).format("MMM D, YYYY")}{" "}
								- {exp.date_left ? moment(exp.date_left).format("MMM D, YYYY") : "Present"}
							</small>
							<p>{exp.job_type}</p>
							<h5>{exp.description ? exp.description : ""}</h5>
						</div>
					))}
			</div>
		</section>
	);
};
