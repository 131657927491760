import { Component } from "react";
import CommentBtn from "./CommentBtn";

export default class CommentHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			commentData: this.props.commentData,
			currentUser: this.props.currentUser,
			time: this.props.time,
		};
	}

	render() {
		var { commentData, time, currentUser } = this.state;

		return (
			<div className="comment--header">
				<div className={`profile-pic ${commentData.user}`}></div>
				<div className="username">{commentData.user}</div>

				{commentData.user === currentUser ? (
					<div className="you-tag">you</div>
				) : (
					""
				)}

				<div className="comment-posted-time">{`${time} ago`}</div>
				<CommentBtn
					commentData={commentData}
					setReplying={this.props.setReplying}
					setDeleting={this.props.setDeleting}
					setDeleteModalState={this.props.setDeleteModalState}
					setEditing={this.props.setEditing}
					currentUser={currentUser}
				/>
			</div>
		);
	}
}
