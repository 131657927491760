import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "./Loader";
import { marked } from "marked";
import hljs from "highlight.js";
import moment from "moment";
import constants from "./Constants";
import AdSense from "react-adsense";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CommentSection from "./CommentSection";

function withParams(Component) {
	return (props) => <Component {...props} params={useParams()} />;
}
marked.setOptions({
	langPrefix: "hljs language-",
	highlight: function (code) {
		return hljs.highlightAuto(code, [
			"html",
			"python",
			"javascript",
			"django",
		]).value;
	},
});

class Post extends Component {
	slug = this.props.params.slug;
	baseUrl = constants.apiDomain + "posts/" + this.slug;

	constructor(props) {
		super(props);
		this.state = {
			post: {},
			isLoaded: false,
			currentUser: "",
			authTokens: localStorage.getItem("authTokens"),
		};
	}

	getData = async () => {
		const response = await fetch(this.baseUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
		// var post = await response.json();
		this.setState({
			post: await response.json(),
			isLoaded: true,
		});
	};
	getCurrentUser = async () => {
		var response = await fetch(constants.apiDomain + "current", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${
					JSON.parse(this.state.authTokens).access
				}`,
			},
		});

		let temp = await response.json();
		this.setState({
			currentUser: temp.username,
		});
	};
	scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	componentDidMount() {
		this.getData();
		if (this.state.authTokens) {
			this.getCurrentUser();
		}

		this.scrollToTop();
	}

	render() {
		var { isLoaded, post, currentUser } = this.state;
		document.title = post.title === undefined ? "Loading..." : post.title;
		if (!isLoaded) {
			return <>{<Loader />}</>;
		} else {
			return (
				<>
					<HelmetProvider>
						<Helmet prioritizeSeoTags>
							<title>{post.title}</title>
							<meta property="og:title" content={post.title} />
							<meta property="og:type" content="article" />
							<meta
								property="og:url"
								content={
									"https://magbanum.com/blog/" + post.slug
								}
							/>
							<meta
								property="og:image"
								content={
									post.hashnode_synced
										? post.cover_image.slice(13)
										: "https://res.cloudinary.com/magbanum/" +
										  post.cover_image
								}
							/>
							<meta
								property="og:description"
								content={post.brief}
							/>
						</Helmet>
						<section className="post">
							<h1>{post.title}</h1>
							<div className="info">
								Shantanu Nighot •{" "}
								{moment(post.publish_date).format(
									"MMM D, YYYY"
								)}{" "}
								•{" "}
								{moment(
									moment(post.publish_date).format(
										"MMM D, YYYY"
									)
								).fromNow()}
							</div>
							<img
								src={
									post.hashnode_synced
										? post.cover_image.slice(13)
										: "https://res.cloudinary.com/magbanum/" +
										  post.cover_image
								}
								alt={post.title}
							/>

							<div
								dangerouslySetInnerHTML={{
									__html: marked(post.content),
								}}
							/>
							<div className="tags">
								{post.tags.map((tag) => (
									<small>{tag.name}</small>
								))}
							</div>
							<section>
								<CommentSection
									id={post.id}
									currentUser={currentUser}
								/>
							</section>

							<section className="long-width-ad">
								<AdSense.Google
									client="ca-pub-3936137909536285"
									slot="8018921473"
									format="auto"
									responsive="true"
									style={{ display: "block" }}
								/>
							</section>
						</section>
					</HelmetProvider>
				</>
			);
		}
	}
}

export default withParams(Post);
