import moment from "moment";
import React from "react";

export const Certifications = (props) => {
    var certifications = props.data.sort((a, b) => (a.id < b.id ? 1 : -1));

	return (
		<section className="edu about">
			<h3>
				<span>C</span>ertification.
			</h3>
			<br />
			<div className="edu-container">
            {certifications &&
					certifications.map((cert) => (
				<div className="edu-box" key={cert.id}>
					<i className="fas fa-circle"></i>
					<h4>{cert.title}</h4>
					<p>{cert.platform}</p>
					<small>{moment(cert.issued_on).format("MMM D, YYYY")}</small>
					<p>{Object.keys(cert.skill_covered).map((key) => cert.skill_covered[key].name)}</p>
					<a href="https://university.mongodb.com/course_completion/8cc33cd2-a347-4099-b5ea-b511d97f2f24">
						See credential
					</a>
				</div>
                    ))}
				
			</div>
		</section>
	);
};
