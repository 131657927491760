import React from "react";

export const Footer = () => {
	return (
		<footer>
			<span>
				<i className="fas fa-code"></i> with <i className="fas fa-heart"></i> by{" "}
				<a href="/">Shantanu</a> powered by{" "}
				<a href="https://github.com/" className="fab fa-github">
					{" "}
				</a>{" "}
			</span>
		</footer>
	);
};
