import React, { Component } from "react";
import "./comment-section/Styles/App.scss";
import "./comment-section/Styles/index.scss";
import Comment from "../components/comment-section/Comment";
import AddComment from "../components/comment-section/AddComment";
import { Loader } from "./Loader";
import constants from "./Constants";

class CommentSection extends Component {
	postId = this.props.id;
	baseUrl = constants.apiDomain + "post/" + this.postId + "/comments";
	constructor(props) {
		super(props);
		this.state = {
			comments: [],
			deleteModalState: false,
			isLoaded: false,
			authTokens: localStorage.getItem("authTokens"),
			currentUser: this.props.currentUser,
		};
	}
	getData = async () => {
		var res;
		try {
			if (this.state.authTokens) {
				res = await fetch(this.baseUrl, {
					method: "GET",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${
							JSON.parse(this.state.authTokens).access
						}`,
					},
				});
			} else {
				res = await fetch(this.baseUrl, {
					method: "GET",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				});
			}
		} catch (error) {
			console.log(error)
			console.log(res)
		}

		const data = await res.json();
		this.setState({
			comments: data,
			isLoaded: true,
			currentUser: this.props.currentUser,
		});
	};

	componentDidMount() {
		this.getData();
		this.state.deleteModalState
			? document.body.classList.add("overflow--hidden")
			: document.body.classList.remove("overflow--hidden");
	}

	// add comments
	addComments = async (newComment) => {
		try {
			fetch(this.baseUrl, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${
						JSON.parse(this.state.authTokens).access
					}`,
				},
				body: JSON.stringify(newComment),
			}).then((response) => {
				if (response.status === 201) {
					this.getData();
				} else {
					alert("Something went wrong");
				}
			});
		} catch (error) {
			alert("Something went wrong", error);
		}
	};
	setDeleteModalState = (state) => {
		this.setState({
			deleteModalState: state,
		});
	};
	// add replies
	updateReplies = (reply) => {
		try {
			fetch(this.baseUrl, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${
						JSON.parse(this.state.authTokens).access
					}`,
				},
				body: JSON.stringify(reply),
			}).then((response) => {
				if (response.status === 201) {
					this.getData();
				} else {
					alert("Something went wrong");
				}
			});
		} catch (error) {
			alert("Something went wrong", error);
		}

		this.getData();
		// updatedComments.forEach((data) => {
		// 	if (data.id === id) {
		// 		data.replies = [...replies];
		// 	}
		// });
		// this.setState({
		// 	comments: updatedComments,
		// });
	};

	// edit comment
	editComment = (content, id, type) => {
		let updatedComments = [...this.state.comments];

		if (type === "comment") {
			updatedComments.forEach((data) => {
				if (data.id === id) {
					data.body = content;
				}
			});
		} else if (type === "reply") {
			updatedComments.forEach((comment) => {
				comment.replies.forEach((data) => {
					if (data.id === id) {
						data.body = content;
					}
				});
			});
		}

		this.setState({
			comments: updatedComments,
		});
	};

	// delete comment
	commentDelete = () => {
		this.getData();
	};

	render() {
		var { isLoaded, comments, currentUser } = this.state;
		if (!isLoaded) {
			return <>{<Loader />}</>;
		} else {
			return (
				<>
					{comments &&
						comments.map((comment) => (
							<Comment
								key={comment.id}
								commentData={comment}
								updateReplies={this.updateReplies}
								editComment={this.editComment}
								commentDelete={this.commentDelete}
								setDeleteModalState={this.setDeleteModalState}
								currentUser={currentUser}
							/>
						))}

					{currentUser && (
						<AddComment
							currentUser={currentUser}
							post={this.postId}
							buttonValue={"send"}
							addComments={this.addComments}
							parent={null}
						/>
					)}
				</>
			);
		}
	}
}

export default CommentSection;
