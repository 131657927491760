import { ReactComponent as IconReply } from "./Assets/images/icon-reply.svg";
import { ReactComponent as IconDelete } from "./Assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "./Assets/images/icon-edit.svg";
import { Component } from "react";

export default class CommentBtn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			commentData: this.props.commentData,
			currentUser: this.props.currentUser && this.props.currentUser,
			counter: false,
		};
	}

	showAddComment = () => {
		this.state.counter
			? (this.props.setReplying(false))
			: (this.props.setReplying(true));
		this.setState({
			counter: true,
		});
	};

	// delete comment
	showDeleteModal = () => {
		this.props.setDeleting(true);
		this.props.setDeleteModalState(true);
	};

	// edit comment
	showEditComment = () => {
		this.props.setEditing(true);
	};

	render() {
		var { commentData, currentUser } = this.state;
		
		return (
			<div className="comment--btn">

				{currentUser && <button
					className={`reply-btn ${
						commentData.user !== currentUser ? "" : "display--none"
					}`}
					onClick={this.showAddComment}
				>
					<IconReply /> Reply
				</button>}
				<button
					className={`delete-btn ${
						commentData.user === currentUser ? "" : "display--none"
					}`}
					onClick={this.showDeleteModal}
				>
					<IconDelete /> Delete
				</button>
				<button
					className={`edit-btn ${
						commentData.user === currentUser ? "" : "display--none"
					}`}
					onClick={this.showEditComment}
				>
					<IconEdit /> Edit
				</button>
			</div>
		);
	}
}
