import React, { Component } from "react";
import { FeaturedProject } from "./FeaturedProject";
import { Loader } from "./Loader";
import { Project } from "./Project";
import constants from "./Constants"
export default class Projects extends Component {
	baseUrl = constants.apiDomain + "projects";

	constructor(props) {
		super(props);
		this.state = {
			items: [],
			isLoaded: false,
		};
	}

	getData = async () => {
		const response = await fetch(this.baseUrl);
		
		this.setState({
			isLoaded: true,
			items: await response.json(),
		});
	};
	componentDidMount() {
		this.getData();
	}

	render() {
		var { isLoaded, items } = this.state;
        var featured = items.filter((item) => item.is_featured)[0]
		document.title = "Projects by Shantanu Nighot"
		if (!isLoaded) {
			return <>{<Loader />}</>;
		} else {
			return (
				<>
					<section className="projects" id="projects">
						<div className="max-width">
							<h2 className="title">projects</h2>
                           
							<FeaturedProject data={featured} />
							<ul className="list">
                                
								{items &&
									items.map((project) => (
										<>
											<li className="card">
												<Project data={project} />
											</li>
										</>
									))}
							</ul>
						</div>
					</section>
				</>
			);
		}
	}
}
